import React, { useState, useEffect } from "react"
import axios from "axios"
import Layout from "../Components/Layout"
import Box from "../Components/Box2"
import { getSearchParams } from "gatsby-query-params"

import SuccessIlliustration from "../assets/images/success-illiustration.jpg"
import { GhostButton, RaisedButton } from "../Components/Buttons"

export default () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [requestResponse, setRequestResponse] = useState(false)
  const params = getSearchParams()

  const urlWithParam = `https://aplinkosaugosegzaminas.lrt.lt/begin/?t=${params.t}`

  useEffect(() => {
    if (params.t) {
      axios
        .post(`https://aplinkosaugosegzaminas.lrt.lt/olimpiada/Api/NAEactivate/${params.t}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(response => {
          setIsLoading(false)
          setIsSuccess(response.data.success === "ok")

          if (response.data) {
            setRequestResponse(response.data)
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [params])

  if (isLoading) {
    return null
  }

  return (
    <Layout>
      <div className="registration-success">
        <Box>
          {isSuccess && (
            <h1 className="tac">
              Jūsų registracija buvo sėkmingai patvirtinta! Susitikime spalio 24 dieną
            </h1>
          )}


          {!isSuccess && <h1 className="tac">{requestResponse.message}</h1>}

          <div className="tac">
            <RaisedButton to={urlWithParam}>Į egzaminą</RaisedButton>
          </div>

          {/*
          <h1 className="tac">
            Sėkmingos registracijos atveju užsuk į viktoriną:
          </h1>

          <RaisedButton to="/start">
            PRADĖTI VIKTORINĄ
          </RaisedButton>



          <div className="tac">
            <img src={SuccessIlliustration} alt="" />
          </div>
          */}
        </Box>
      </div>
    </Layout>
  )
}
